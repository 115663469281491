.about{
    width: 80%;
    height: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    color: #fff;
}

.Information{
    width: 100%;
    text-align: center;
    font-size: 3rem;
}

.Introductions{
    width: 33.333%;
    display: flex;
    justify-content: center;
}

.Intro{
    width: 70%;
    height: 70%;
}

.IntroductionImg{
    width: 50%;
    height: 50%;
    margin: 0 auto;
    margin-bottom: 10%;
}

.IntroductionEx{
    width: 100%;
    word-break: break-all;
}

.Introduction{
    font-size: 1.25rem;
    text-align: center;
    white-space: pre-line;
}