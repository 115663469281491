.contact_email{
    box-sizing: border-box;
    margin: 0 auto;
    width: 30%;
  }
  .contact{
    text-align: center;
    font-size: 3rem;
  }
  
  .input_send{
    width: 100%;
    margin-top: 12%;
  }
  .name, .email, .phone, .message{
    background: none;
    width: 100%;
    border: 0;
    border-bottom: 1px solid;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-size: 1rem;
    outline: none;
    margin-bottom: 11%;
    font-family: "Pretendard-Regular";
  }
  
  .name::placeholder, .email::placeholder, .phone::placeholder, .message::placeholder{
    color: rgba(255, 255, 255, 0.3);
  }
  .send{
    background: transparent;
    color: #fff;
    font-size: 1rem;
    margin-top: 12%;
    outline: 0;
    padding: 2.2% 0;
    border: 2px solid #fff;
    cursor: pointer;
    transition: all 0.5s;
    width: 100%;
  }
  
  .send:hover{
    background: #007aff;
    border-color: #007aff;
  }