  .mwelcome{
    position: absolute;
    top: 30%;
    left: 10%;
    font-size: 8rem;
  }
  
  .mpopol{
    position: absolute;
    top: 50%;
    right: 15%;
    font-size: 8rem;
  }

  .mtutorial{
    position: absolute;
    bottom: 10%;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 5rem;
  }